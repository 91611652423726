<template>
   <div class="flex space-x-2">
      <div class="w-60">
         <div>
            <div class="flex justify-center mb-5">
               <SelectButton v-model="deviceSize" :options="options" optionLabel="deviceSize" dataKey="deviceSize" aria-labelledby="custom">
                     <template #option="slotProps">
                        <i :class="slotProps.option.icon" v-tooltip.top="slotProps.option.tooltipText"></i>
                     </template>
               </SelectButton>
            </div>
            <label class="font-bold block mb-2"> Company Name </label>
            <InputText type="text" v-model="companyName" />
            <label for="cp-hex" class="font-bold block my-2"> Banner Color </label>
            <InputGroup>
               <InputGroupAddon>HEX</InputGroupAddon>
               <InputText placeholder="Banner Color" v-model="bannerHex"/>
               <InputGroupAddon>
                  <ColorPicker v-model="bannerHex" inputId="cp-hex" format="hex" />
               </InputGroupAddon>
            </InputGroup>

            <label for="cp-hex" class="font-bold block mb-2 mt-4"> Search Color </label>
            <InputGroup>
               <InputGroupAddon>HEX</InputGroupAddon>
               <InputText placeholder="Banner Color" v-model="searchHex"/>
               <InputGroupAddon>
                  <ColorPicker v-model="searchHex" inputId="cp-hex" format="hex" />
               </InputGroupAddon>
            </InputGroup>

            <label for="cp-hex" class="font-bold block mb-2 mt-4"> Site Background </label>
            <InputGroup>
               <InputGroupAddon>HEX</InputGroupAddon>
               <InputText placeholder="Banner Color" v-model="bgHex"/>
               <InputGroupAddon>
                  <ColorPicker v-model="bgHex" inputId="cp-hex" format="hex" />
               </InputGroupAddon>
            </InputGroup>
         </div>
         

         <div class="mt-10">
            <div>Sidebar</div>
            <div class="flex justify-center">
               <Checkbox v-model="showSideBar" :binary="true" />
            </div>
         </div>
      </div>

      <div class="flex-1">
         <div class="border pt-2 rounded border-gray-600 m-auto" :class="{ tablet: isTablet, mobile: isMobile }">
            <div class="flex pl-3 preview-header space-x-2">
               <div class="header-btn red"></div>
               <div class="header-btn yellow"></div>
               <div class="header-btn green"></div>
            </div>
            <div class="bg-gray-500 flex mt-2 p-1 pl-1 rounded-t-md">
               <div><i class="ml-2 pi pi-arrow-left"></i> <i class="pi pi-arrow-right ml-2"></i> </div>
               <div class="bg-black/50 ml-4 rounded-2xl w-1/2"></div>
            </div>
            <div :style="previewBannerStyle" class="flex h-12 px-4 items-center justify-center preview-banner">
               <div class="flex-1 font-semibold">
                  {{ companyName }}
               </div>
               <div>
                  <i class="ml-2 pi pi-bars sm-show cursor-pointer" :class="{'show-i': isMobile || isTablet}"></i>
               </div>
            </div>
            <div :style="searchBannerStyle" class="flex h-12 items-center justify-center preview-search">
               <div class="w-1/2">
                  <InputGroup>
                     <InputText :placeholder="searchText" />
                     <Button label="Search" />
                  </InputGroup>
               </div>
            </div>
            <div :style="bgStyle" class="flex text-gray-500">
               <div class="sm-hidden w-60" v-if="showSideBar && !isMobile && !isTablet">
                  sidebar
               </div>
               <div class="flex-1 justify-between m-8 md:flex" :class="{'block-i': isMobile || isTablet}">
                  <div class="card">1</div>
                  <div class="card">1</div>
                  <div class="card">1</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   data() {
      return {
         bannerHex: '2C323D',
         companyName: 'Acme Corp',
         bgHex: 'ffffff',
         searchHex: 'b1b1b3',
         showSideBar: false,
         deviceSize: 'desktop',
         options: [
            { icon: 'pi pi-desktop', deviceSize: 'desktop', tooltipText: "Desktop" },
            { icon: 'pi pi-tablet', deviceSize: 'tablet', tooltipText: "Tablet" },
            { icon: 'pi pi-mobile', deviceSize: 'mobile', tooltipText: "Mobile" }
         ]
      };
   },
   name: "SitesEditor",

   components: {
   },
   mounted() {
   },
   methods: {
   },
   computed: {
      searchText() {
         return `Search ${this.companyName}`;
      },

      previewBannerStyle() {
         return {
            backgroundColor: `#${this.bannerHex}`
         };
      },

      searchBannerStyle() {
         return {
            backgroundColor: `#${this.searchHex}`
         };
      },

      bgStyle() {
         return {
            backgroundColor: `#${this.bgHex}`
         };
      },

      isTablet() {
         return this.deviceSize.deviceSize === 'tablet';
      },

      isMobile() {
         return this.deviceSize.deviceSize === 'mobile';
      }
   }
}
</script>

<style scoped lang="scss">
   .header-btn {
      height: 10px;
      width: 10px;
      border-radius: 50%;

      &.red {
         background-color: crimson;
      }

      &.green {
         background-color: lightgreen;
      }

      &.yellow {
         background-color: goldenrod;
      }
   }

   .tablet {
      width: 600px;
   }

   .mobile {
      width: 300px;
   }

   .show-i {
      display: inline !important
   }

   .block-i {
      display: block !important;
   }
</style>