<template>
  <div ref="dropdownRef" class="relative">
    <button @click="toggleDropdown" :class="['border cursor-pointer focus:outline-none hg-primary-bg hg-primary-text hg-primary-border outline-none px-4 py-2 rounded-md shadow-sm text-left', classes]">
      <span class="block pr-4 truncate" :class="{'text-gray-400': !selectedItem}">{{ selectedItem ? selectedItem.label : placeholder }}</span>
      <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </span>
    </button>

    <div v-if="isOpen" class="absolute z-10 right-0 mt-1 rounded-md hg-primary-bg shadow-lg fade-in border hg-primary-border">
      <ul tabindex="-1" class="rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm p-2 max-h-auto">
        <li v-for="item in items" :key="item.id" @click="selectItem(item)" :class="['cursor-pointer select-none hg-primary-hover-item relative py-2 pl-3 pr-9 rounded', selectedItem && selectedItem.id === item.id ? 'hg-primary-active' : '']">
          <span class="block truncate">{{ item.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, watch, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Object,
    default: null,
  },
  placeholder: {
    type: String,
    default: 'Select an option...',
  },
  classes: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:modelValue']);

const isOpen = ref(false);
const selectedItem = ref(props.modelValue);
const dropdownRef = ref(null);

watch(
  () => props.modelValue,
  (newValue) => {
    selectedItem.value = newValue;
  }
);

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectItem(item) {
  selectedItem.value = item;
  emit('update:modelValue', item);
  isOpen.value = false;
}

function handleClickOutside(event) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped lang="scss">
.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
