<template>
  <div class="tabs">
    <div
      v-for="(label, index) in labelsOrSlots"
      :key="index"
      @click="selectTab(label)"
      :class="['tab', { active: label === currentValue }]">
      <slot v-if="!isLabelsArray" :name="label"></slot>
      <span v-else>{{ label }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance, defineProps, defineEmits } from 'vue';

// Define props
const props = defineProps({
  labels: {
    type: Array,
    default: () => []
  },
  modelValue: {
    type: String,
    default: ''
  }
});

// Emit event for two-way binding
const emit = defineEmits(['update:modelValue']);

// Ref for the currently selected tab value
const currentValue = ref(props.modelValue);

// Determine if labels are provided
const isLabelsArray = computed(() => props.labels.length > 0);

// Method to handle tab selection
const selectTab = (label) => {
  currentValue.value = label;
  emit('update:modelValue', label);
};

// Computed property to manage labels or slots
const labelsOrSlots = computed(() => {
  return isLabelsArray.value ? props.labels : Object.keys(getCurrentInstance().slots);
});

// Watch for external changes to modelValue
watch(() => props.modelValue, (newVal) => {
  currentValue.value = newVal;
});
</script>

<style scoped lang="scss">
.dark {
  .tabs {
    display: flex;
    background-color: #27272a;
    padding: 5px;
    border-radius: 6px;
  }

  .tab {
    cursor: pointer;
    padding: 2px 10px;
    color: #a1a1aa;
    background-color: #27272a;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 6px;
  }

  .tab.active {
    background-color: #09090b;
    color: #fff;
  }
}

.tabs {
  display: flex;
  background-color: var(--light-secondary-background);
  padding: 5px;
  border-radius: 6px;
}

.tab {
  cursor: pointer;
  padding: 2px 10px;
  color: var(--light-secondary-color);
    background-color: var(--light-secondary-background);
  transition: background-color 0.3s, color 0.3s;
  border-radius: 6px;
}

.tab.active {
  background-color: var(--light-primary-background);
  color: var(--light-primary-color);
}
</style>
