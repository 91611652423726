<template>
  <div class="relative" ref="tooltipRef" @mouseover="showTooltip" @mouseleave="hideTooltip">
    <!-- Use a scoped slot to apply hover events to the slot content -->
    <slot
      :showTooltip="showTooltip"
      :hideTooltip="hideTooltip"
      >
    </slot>
    <div
      v-if="visible"
      :class="['absolute', positionClass, 'hg-primary-bg hg-primary-text hg-primary-border border whitespace-nowrap text-sm px-2 py-1 rounded', 'tooltip-animation']"
      ref="tooltipContent"
    >
      {{ text }}
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  position: {
    type: String,
    default: 'top', // top, bottom, left, right
  },
});

const emit = defineEmits(['update:modelValue']);

const visible = ref(false);
const tooltipRef = ref(null);
const tooltipContent = ref(null);
const positionClass = ref('');

// Functions to show and hide tooltip
const showTooltip = () => {
  visible.value = true;
  updatePosition();
};

const hideTooltip = () => {
  visible.value = false;
};

const updatePosition = () => {
  if (tooltipRef.value && tooltipContent.value) {
    if (props.position === 'top') {
      positionClass.value = `-top-1 left-1/2 transform -translate-x-1/2 -translate-y-full`;
    } else if (props.position === 'bottom') {
      positionClass.value = `bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full`;
    } else if (props.position === 'left') {
      positionClass.value = `left-0 top-1/2 transform -translate-x-full -translate-y-1/2`;
    } else if (props.position === 'right') {
      positionClass.value = `right-0 top-1/2 transform translate-x-full -translate-y-1/2`;
    }
  }
};

// Update position on mounted to correctly align the tooltip
onMounted(() => {
  updatePosition();
  window.addEventListener('resize', updatePosition);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updatePosition);
});
</script>

<style scoped>
.tooltip-animation {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

[v-cloak] .tooltip-animation {
  opacity: 1;
}
</style>
