<template>
  <div class="flex h-full">
    <div class="flex-1 ">
      <div class="border-b hg-primary-border flex items-center py-2">
        <div @click="backToInbox" class="hg-tertiary-btn cursor-pointer mx-3 p-2 text-center w-10">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
        </div>
        <div class="flex-1 pl-2">
          <div>
            <div class="font-bold hg-primary-text py-1">Conversation #{{ conversationStore.conversation.id }}</div>
            <div class="flex space-x-2" v-if="false">
              <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">Badge</span>
              <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Badge</span>
              <span class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Badge</span>
              <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Badge</span>
            </div>
          </div>

        </div>
        <div class="flex space-x-2 pr-2">
          <div>
          <div class="flex justify-center">
              <Tabs :labels="statuses" v-model="selectedStatus" @update:modelValue="statusUpdated"/>
          </div>
          </div>
            <Dropdown
              v-model="selectedCity"
              :items="cities"
              placeholder="Assign"
              :classes="`w-40`"
            />

        </div>
      </div>
      <div id="chat-area" class="chats-area m-auto max-w-4xl overflow-y-scroll p-2">
        <div v-for="message in conversationStore.conversation.messages">
          <div v-if="isCustomer(message)">
            <div class="border hg-primary-border message mr-10 ml-4 mt-2 p-2 rounded rounded-lg">
              <div class="px-4 hg-primary-border">
                <div class="flex hg-primary-text pr-3">
                  <div class="flex-1  font-semibold">
                    {{ ownerName(message) }}
                  </div>
                  <div class="flex italic text-sm">{{ formattedDate(message) }}</div>
                </div>

                <div class="hg-secondary-text" v-html="messageConentHtml(message)">
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="hg-secondary-bg border hg-primary-border message ml-10 mr-4 mt-2 p-2 rounded rounded-lg">
              <div class="pl-4 hg-secondary-border">
                <div class="flex hg-primary-text pr-3">
                  <div class="flex-1  font-semibold">
                    {{ ownerName(message) }}
                  </div>
                  <div class="flex italic  text-sm">{{ formattedDate(message) }}</div>
                </div>

                <div class="hg-secondary-text" v-html="messageConentHtml(message)">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="flex items-center justify-center pt-3">
        <div class="border hg-primary-border flex items-end max-w-4xl p-2 rounded-lg w-full">
          <Tooltip text="Attach file" position="top">
          <button class="hg-tertiary-btn">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
            </svg>
          </button>
          </Tooltip>

          <ConversationEditor
            ref="editorRef"
            class="editor"
            v-model="messageContent"
            @update:modelValueJson="updateJson"
            @update:model-value-html="updateHtml"
          ></ConversationEditor>

          <button class="hg-primary-btn ml-2 p-2 rounded-full" @click="submitMessage">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 rotate">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="hg-primary-border border-l px-2 w-72">
      <div class="border hg-primary-border mt-2 rounded-xl">
        <div class="p-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="bg-white h-12 p-2 rounded-full text-gray-800">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
          </svg>

          <h5 class="antialiased block border-b hg-primary-border font-sans font-semibold leading-snug my-2 pb-3 text-xl tracking-normal">
            {{ conversationStore.conversation.customer.firstName }} {{ conversationStore.conversation.customer.lastName }}
          </h5>

          <h5 v-if="hasPreviousConversations(previousConversations)" class="antialiased block font-sans font-semibold leading-snug my-1  text-xl tracking-normal">Previous Conversations</h5>
          <div>
            <div v-for="prevConvo in previousConversations" @click="conversationStore.viewConversation(prevConvo, router)" class="hg-tertiary-btn pl-2">
              {{ prevConvo.firstMessage.contentHtml }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-2 justify-center">
        <button type="button" class="flex text-center items-center justify-center space-x-2  font-bold hg-primary-btn py-3 rounded-md text-lg  w-full">
          Summarize
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 ml-2">
              <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import ConversationEditor from '/entrypoints/components/conversations/ConversationEditor.vue';
import { useRouter } from 'vue-router';
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useConversationStore } from '/entrypoints/stores/conversationStore.js';
import { useCurrentUserStore } from '/entrypoints/stores/currentUserStore';
import { useProductStore } from '/entrypoints/stores/productStore.js';
import { useWsMessageStore } from '/entrypoints/stores/websockets/wsMessageStore.js';
import Tabs from '/entrypoints/components/shared/Tabs.vue';
import { jumpToBottom } from '/entrypoints/utils/scrollUtils.js';
import Dropdown from '/entrypoints/components/shared/Dropdown.vue';
import Tooltip from '/entrypoints/components/shared/Tooltip.vue';

import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTimePlugin);

const router = useRouter();
const conversationStore = useConversationStore();
const currentUserStore = useCurrentUserStore();
const productStore = useProductStore();
const wsMessageStore = useWsMessageStore();

const selectedStatus = ref("")
const statuses = [ "Active", "Pending", "Closed"]
const messageContent = ref('');
const messagePlaceholder = ref('');
const previousConversations = ref([]);
const editorRef = ref(null)
const selectedCity = ref(null);
const cities = ref([
  { id: 1, label: 'New York' },
  { id: 2, label: 'Rome' },
  { id: 3, label: 'London' },
  { id: 4, label: 'Istanbul' },
  { id: 5, label: 'Paris' },
]);

const hasPreviousConversations = (conversations) => {
  return conversations.length > 0;
}

const updateJson = (val) => {
  conversationStore.conversation.content = val;
}

const updateHtml = (val) => {
  conversationStore.conversation.contentHtml = val;
}

const submitMessage = () => {
  conversationStore.sendMessage();
  if (editorRef.value) {
    editorRef.value.clearContent()
  }
};

const isCustomer = (message) => {
  if (message === undefined) {return;}
  return message.owner.type === "Customer";
}

const messageConentHtml = (message) => {
  if (message === undefined) {return;}
  return message.contentHtml;
}

const isAccountUser = (type) => {
  return type === "AccountUser";
}

const formattedDate = (message) => {
  if (message === undefined) {return;}
   const date = dayjs(message.createdAt);
   return date.fromNow();
};

const statusUpdated = (value) => {
  const conversationId = conversationStore.conversation.id;
  const newStatus = value;
  conversationStore.updateConversationStatus(conversationId, newStatus);
};

const uppercase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ownerName = (message) => {
  if (message === undefined) {return;}
  const owner = message.owner;
  if (isAccountUser(owner.type)) {
    if(owner.id === currentUserStore.user.id) {
      return "You"
    } else {
      return owner.firstName + " " + owner.lastName;
    }
  } else {
    return owner.firstName + " " + owner.lastName;
  }
}

const backToInbox = () => {
  router.push(`/inbox/${productStore.product.id}`);
}

onMounted(() => {
  jumpToBottom('chat-area');

  const conversationId = router.currentRoute.value.params.id;
  if (!conversationStore.conversation || !conversationStore.conversation.id) {
    conversationStore.getConversation(conversationId);
  }

  watch(() => conversationStore.conversation, (newConversation) => {
    if (newConversation && newConversation.status) {
      selectedStatus.value = uppercase(newConversation.status);
      messagePlaceholder.value = "Message " + newConversation.customer.firstName + " " + newConversation.customer.lastName;
      previousConversations.value = newConversation.customer.conversations.filter(convo => convo.id !== newConversation.id);

      wsMessageStore.connect(conversationStore.conversation.id);
    }
  }, { immediate: true });
});

onUnmounted(() => {
  wsMessageStore.disconnect();
});

</script>

<style>
.chats-area {
  height: calc(100vh - 242px);
}

.editor {
  width: 100%;
  overflow: scroll;
  height: 110px;
}

.rotate {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(-45deg);
}
</style>
