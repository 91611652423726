<template>
   <div>
      Email
   </div>
</template>

<script>
   export default {

   }
</script>