<template>
  <div v-if="editor" class="tiptap">
    <button @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
      bold
    </button>
    <button @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
      italic
    </button>
    <button @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
      strike
    </button>
    <button @click="editor.chain().focus().toggleCode().run()" :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
      code
    </button>
    <button @click="editor.chain().focus().unsetAllMarks().run()">
      clear marks
    </button>
    <button @click="editor.chain().focus().clearNodes().run()">
      clear nodes
    </button>
    <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
      paragraph
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
      h1
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
      h2
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
      h3
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
      h4
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
      h5
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
      h6
    </button>
    <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
      bullet list
    </button>
    <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
      ordered list
    </button>
    <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
      code block
    </button>
    <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
      blockquote
    </button>
    <button @click="editor.chain().focus().setHorizontalRule().run()">
      horizontal rule
    </button>
    <button @click="editor.chain().focus().setHardBreak().run()">
      hard break
    </button>
    <button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
      undo
    </button>
    <button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
      redo
    </button>
  </div>
  <editor-content :editor="editor" />
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Commands from './extensions/commands.js'
import suggestion from './extensions/suggestion.js'
import { ColumnExtension } from "@gocapsule/column-extension";
// don't forget to add style to see the columns
// import "@gocapsule/column-extension/src/index.css";

export default {
  components: {
    EditorContent,
  },

  data() {
    return {
      editor: null,
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure(),
        ColumnExtension,
        Commands.configure({
          suggestion,
        }),
      ],
      content: `
        <h2>
          Hi there,
        </h2>`,
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss" >
/* Basic editor styles */


  // .ProseMirror .column-block {
  //   width: 100%;
  //   display: grid;
  //   grid-auto-flow: column;
  //   grid-auto-columns: 1fr;
  //   gap: 24px;
  //   padding: 8px 0;
  // }

  // .ProseMirror .column {
  //   overflow: auto;
  //   border: 1px gray dashed;
  //   border-radius: 8px;
  //   padding: 8px;
  //   margin: -8px;
  // }

  // [contenteditable] {
  //   outline: 1px solid #5B616B;
  //   margin: 10px 10px;
  //   border-radius: 5px;
  //   padding: 15px;
  //   height: calc(100vh - 212px);
  //   overflow: scroll;
  // }

  // [contenteditable]:focus {
  //   outline: 1px solid #5B616B;
  //   margin: 10px 10px;
  //   border-radius: 5px;
  //   padding: 15px;
  // }
  // button {
  //   margin: 1px 5px;
  //   background: gray;
  //   padding: 0 6px;
  //   border-radius: 3px;
  //   }
  // // .tiptap {
  //   > * + * {
  //     margin-top: 0.75em;
  //   }

  //   ul,
  //   ol {
  //     padding: 0 1rem;
  //   }

  //   h1,
  //   h2,
  //   h3,
  //   h4,
  //   h5,
  //   h6 {
  //     line-height: 1.1;
  //   }

  //   code {
  //     background-color: rgba(#616161, 0.1);
  //     color: #616161;
  //   }

  //   pre {
  //     background: #0D0D0D;
  //     color: #FFF;
  //     font-family: 'JetBrainsMono', monospace;
  //     padding: 0.75rem 1rem;
  //     border-radius: 0.5rem;

  //     code {
  //       color: inherit;
  //       padding: 0;
  //       background: none;
  //       font-size: 0.8rem;
  //     }
  //   }

  //   img {
  //     max-width: 100%;
  //     height: auto;
  //   }

  //   blockquote {
  //     padding-left: 1rem;
  //     border-left: 2px solid rgba(#0D0D0D, 0.1);
  //   }

  //   hr {
  //     border: none;
  //     border-top: 2px solid rgba(#0D0D0D, 0.1);
  //     margin: 2rem 0;
  //   }
  // // }


</style>