<template>
  <div class="h-full flex hg-primary-text">
     <div class="border-r hg-primary-border w-40">
        <div class="px-3 py-2 mx-3 my-1 rounded-lg font-semibold  cursor-pointer flex ">
           <div class="flex-1">Mine</div>
           <div>4</div>
        </div>
        <div class="px-3 py-2 mx-3 my-1 rounded-lg font-semibold  cursor-pointer flex">
           <div class="flex-1">Mentions</div>
           <div></div>
        </div>
        <div class="px-3 py-2 mx-3 my-1 rounded-lg font-semibold  cursor-pointer flex">
           <div class="flex-1">All</div>
           <div></div>
        </div>

        <div class="px-3 py-2 mx-3 my-1 rounded-lg font-semibold  cursor-pointer flex ">
           <div class="flex-1">Unassigned</div>
           <div></div>
        </div>

        <div class="px-3 py-2 mx-3 my-1 rounded-lg font-semibold  cursor-pointer flex ">
           <div class="flex-1">Drafts</div>
           <div>2</div>
        </div>

        <div class="px-3 py-2 mx-3 my-1 rounded-lg font-semibold  cursor-pointer flex ">
           <div class="flex-1">Unassigned</div>
           <div></div>
        </div>
     </div>
     <div class="flex-1 flex overflow-hidden">
        <div class="px-3 flex-1 overflow-y-scroll">
            <div v-if="conversationStore.conversations.length === 0" class="flex hg-secondary-bg justify-center m-auto mt-3 p-8">
               <h2 class="hg-primary-text">You don't have any conversations yet</h2>
            </div>
            <div v-else class="shadow text-center rounded-lg my-2">
               <div class="flex py-2">
                  <div class="w-10 mr-6"><input type="checkbox"></div>
                  <div class=" w-24 text-left">ID</div>
                  <div class="w-64  text-left">Customer</div>
                  <div class=" flex-1 text-left">Conversation</div>
                  <div class=" w-40 text-left">Waiting</div>
               </div>
               <div v-for="conversation in conversationStore.conversations" @click="conversationStore.viewConversation(conversation, router)" class="flex py-3 white border-t hover:shadow hover:border-l-4 hover:border-x-blue-300 cursor-pointer border-t-surface-600 hg-primary-border" :class="{ 'font-bold hg-secondary-bg': conversation.hasUnreadMessages }">
                  <div class="w-10 mr-6"><input type="checkbox"></div>
                  <div class="w-24 text-left">{{ conversation.id }}</div>
                  <div class="w-64 text-left">{{ conversation.customer.name }}</div>
                  <div class="flex-1 overflow-ellipsis text-left text-nowrap overflow-auto">{{ conversation.subject }}</div>
                  <div class="w-40 text-left">{{ formattedDate(conversation.mostRecentMessage.createdAt) }}</div>
               </div>
            </div>
        </div>
     </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useConversationStore } from '/entrypoints/stores/conversationStore.js';
import dayjs from 'dayjs';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTimePlugin);

const route = useRoute();
const router = useRouter();
const id = route.params.id;
const conversationStore = useConversationStore();

conversationStore.fetchConversations(id);

const formattedDate = (dateString) => {
  const date = dayjs(dateString);
  return date.fromNow(true);
};

</script>

