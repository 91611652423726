import { createRouter, createWebHistory } from "vue-router";
import SiteEditor from "./components/sites/Editor.vue";
import ArticlesIndex from "./components/articles/ArticlesIndex.vue";
import ArticlesEditor from "./components/articles/ArticlesEditor.vue";
import InboxIndex from "/entrypoints/pages/Inbox.vue";
import EmailsIndex from "./components/products/emails/EmailsIndex.vue";
import ChatbotsIndex from "./components/products/chatbots/ChatbotsIndex.vue";
import Home from "./components/home/Home.vue";
import Conversation from '/entrypoints/pages/Conversation.vue';
import NewDocs  from './components/products/docs/New.vue';

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", component: Home },
        { path: "/inbox/:id", component: InboxIndex },
        { path: "/conversations/:id", component: Conversation },
        { path: "/products/:id/chatbot", component: ChatbotsIndex },
        { path: "/products/:id/emails", component: EmailsIndex },
        { path: "/docs/manage-sites", component: SiteEditor },
        { path: "/docs", component: ArticlesIndex },
        { path: "/docs/edit/:id", component: ArticlesEditor },
        { path: "/docs/new", component: NewDocs }
    ]
});
