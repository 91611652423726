<template>
  <editor-content :editor="editor" class="w-full mx-auto focus:outline-none"/>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Placeholder from '@tiptap/extension-placeholder'
import { defineExpose } from 'vue'

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValueHtml: {
      type: String,
      default: '',
    },
    modelValueJson: {
      type: Object,
      default: '',
    },
  },

  emits: ['update:modelValueJson', 'update:modelValueHtml'],

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Send a message...'
        })
      ],
      content: this.modelValueHtml,
      onUpdate: () => {
        this.$emit('update:modelValueHtml', this.editor.getHTML())
        this.$emit('update:modelValueJson', this.editor.getJSON())
      },
    })
  },

  methods: {
    clearContent() {
      this.editor.commands.clearContent()  // Clear the content
      this.editor.commands.focus('start')  // Focus on the editor to potentially trigger placeholder visibility

      // Trigger the placeholder by setting content to empty
      if (this.editor.isEmpty) {
        this.editor.commands.setContent('')
      }
    }
  },

  beforeUnmount() {
    this.editor.destroy()
  },

  setup() {
    defineExpose({
      clearContent() {
        this.clearContent()
      }
    })
  }
}
</script>

<style lang="scss" scoped>

</style>



