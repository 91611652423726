
<template>
   <div>
       <DataTable v-model:selection="selectedProduct" :value="products" selectionMode="single" dataKey="id" :metaKeySelection="false"
               @rowSelect="onRowSelect" @rowUnselect="onRowUnselect" tableStyle="min-width: 50rem">
           <Column field="code" header="Code"></Column>
           <Column field="name" header="Name"></Column>
           <Column field="category" header="Category"></Column>
           <Column field="quantity" header="Quantity"></Column>
       </DataTable>
       <Toast/>
   </div>
</template>

<script>

export default {
   data() {
       return {
           products: null,
           selectedProduct: null
       };
   },
   methods: {
       onRowSelect(event) {
            console.log(event)
            this.$router.push('/docs/edit/1');
           this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
       },
       onRowUnselect(event) {
           this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
       }
   }
};
</script>
